import styled, { css } from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 7.5%;
`;

export const Col = styled.div(
  ({ theme: { downTo }, width = "50%" }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: ${width};

    ${downTo("sm")} {
      width: 100%;
    }
  `
);
