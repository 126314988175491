import { Col, Row } from "../../../../../components/Grid";
import { AboutContainer, Image } from "./styles";
import { Heading2 } from "../../../../../components/text/Heading";
import { Paragraph1 } from "../../../../../components/text/Paragraph";
import AboutImage from "../../../../../assets/aboutUs";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const About = () => {
  const { t } = useTranslation("translation");
  return (
    <AboutContainer>
      <Row>
        <Col>
          {isMobile && <Heading2>{t("aboutCulbot.title")}</Heading2>}
          <Image
            src={AboutImage.CulbotEquipment}
            alt={t("aboutCulbot.title")}
          />
        </Col>
        <Col>
          {!isMobile && <Heading2>{t("aboutCulbot.title")}</Heading2>}
          <Paragraph1>{t("aboutCulbot.description")}</Paragraph1>
        </Col>
      </Row>
    </AboutContainer>
  );
};

export default About;
