import React from "react";
import { Image, CardWrapper } from "./styles";
import { Heading2 } from "../../text/Heading";
import { Paragraph1 } from "../../text/Paragraph";
import { Row } from "../../Grid";

const Card = ({ image, title, description }) => (
  <CardWrapper>
    <Row>
      <Image src={image} alt={title} />
    </Row>
    <Heading2>{title}</Heading2>
    <Paragraph1>{description}</Paragraph1>
  </CardWrapper>
);

export default Card;
