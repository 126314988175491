import styled, { css } from "styled-components";

export const Image = styled.img`
  width: 100%;
  display: block;
  max-height: 50vh;
`;

export const CardWrapper = styled.div(
  ({ theme: { downTo } }) => css`
    margin: 2rem 5rem;
    ${downTo("lg")} {
      margin: 2rem 2rem;
    }
  `
);
