import styled from "styled-components";

export const AboutContainer = styled.div`
  position: relative;
  padding: 1rem;
`;

export const Image = styled.img`
  width: 100%;
  display: block;
  max-height: 50vh;
`;
