import Configuration from "./manual-configuration.svg";
import ParametersTest from "./parameters-test.svg";
import RealTimeMonitoring from "./real-time-monitoring.svg";
import AutoConfiguration from "./auto-configuration.svg";

const MainFunctions = {
  RealTimeMonitoring,
  AutoConfiguration,
  Configuration,
  ParametersTest,
};

export default MainFunctions;
