import header from "./headerES.json";
import video from "./videoES.json";
import purchase from "./purchaseES.json";
import functioning from "./functioningES.json";
import features from "./featuresES.json";
import enterNow from "./enterNowES.json";
import contact from "./contactES.json";
import benefits from "./benefitsES.json";
import aboutUs from "./aboutUsES.json";
import aboutCulbot from "./aboutCulbotES.json";

const translation = {
  header,
  video,
  purchase,
  functioning,
  features,
  enterNow,
  contact,
  benefits,
  aboutUs,
  aboutCulbot,
};

export default translation;
