import React from "react";
import MainFeatures from "./sections/MainFeatures";
import About from "./sections/About";

const ViewsContainer = () => (
  <>
    <About />
    <MainFeatures />
  </>
);

export default ViewsContainer;
