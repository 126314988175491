import styled, { css } from "styled-components";

export const Paragraph1 = styled.p(
  ({ theme: { colors, downTo, typography } }) => css`
    font-size: ${typography.sizes.large};
    font-family: "InterRegular";
    font-style: normal;
    color: ${colors.textMedium};
    ${downTo("md")} {
      font-size: ${typography.sizes.medium};
    }
  `
);
export const Paragraph2 = styled.p(
  ({ theme: { typography, downTo } }) => css`
    font-size: ${typography.sizes.xlarge};
    font-family: "InterSemiBold";
    font-style: normal;
    ${downTo("md")} {
      font-size: ${typography.sizes.medium};
    }
    ${downTo("sm")} {
      font-family: "InterMedium";
      font-size: ${typography.sizes.large};
    }
  `
);
export const Paragraph3 = styled.p(
  ({ theme: { typography } }) => css`
    font-size: ${typography.sizes.medium};
    font-family: "InterBold";
    font-style: normal;
  `
);

export const Feedback = styled.p(
  ({ theme: { typography, downTo } }) => css`
    font-size: ${typography.sizes.medium};
    font-family: "InterRegular";
    font-style: normal;
    ${downTo("md")} {
      font-size: ${typography.sizes.small};
    }
  `
);
