import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import Fonts from "./assets/fonts/fontFaces";
import Theme from "./commons/Theme";
import { ThemeStore } from "./commons/Theme/store";

ReactDOM.render(
  <React.StrictMode>
    <Fonts />
    <ThemeStore>
      <Theme>
        <App />
      </Theme>
    </ThemeStore>
  </React.StrictMode>,
  document.getElementById("root")
);
